<!-- 交通区位 -->
<template>
  <div class="move-frame">
    <div class="body">
      <top showTitle="走进黑水" :childNavs="navs"></top>
      <div class="content">
        <div class="left">
          <img
            class="full"
            src="../../assets/move/fullQueen.png"
            title="全屏"
            alt="全屏"
            @click="setFull(true)"
          />
          <div id="container"></div>
        </div>
        <div class="right">
          <div class="hover-box">
            <video
              v-if="$store.globalSet.publicityVideo"
              class="video"
              :src="URL + $store.globalSet.publicityVideo"
              controls
              controlsList="nodownload"
              preload="auto"
            ></video>
            <div class="desc-bottom">
              <div class="desc" v-html="$store.globalSet.contentStr"></div>
            </div>
          </div>
        </div>
      </div>
      <bottom></bottom>

      <!-- 全屏地图 -->
      <div class="dialog" v-show="isFull">
        <img
          class="full"
          src="../../assets/move/exitFull.png"
          title="退出全屏"
          alt="退出全屏"
          @click="setFull(false)"
        />
        <div id="full"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Bottom from "../../components/bottom.vue";
import top from "../../components/top.vue";
import routes from "../../router/moveWater";
import { URL } from "../../util/config";

import AMapLoader from "@amap/amap-jsapi-loader";
export default {
  components: { top, Bottom },
  data() {
    return {
      URL,
      navs: routes,
      map: null,
      AMap: null,
      fullmap: null, // 全屏地图
      isFull: false, // 判断地图是否全屏
    };
  },
  created() {},
  mounted() {
    this.initMap();
  },
  methods: {
    initMap() {
      let that = this;
      AMapLoader.load({
        key: this.$mapKey,
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: ["AMap.DistrictSearch"], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      })
        .then((AMap) => {
          that.AMap = AMap;
          that.map = new AMap.Map("container", {
            //设置地图容器id
            zoom: 14, //初始化地图级别
            center: [102.987592, 32.069268], //初始化地图中心点位置
            layers: [AMap.createDefaultLayer(), new AMap.TileLayer.RoadNet()],
          });
          var opts = {
            subdistrict: 1, //返回下一级行政区
            level: "city", //查询的范围
            showbiz: false, //最后一级返回街道信息
          };
          let district = new AMap.DistrictSearch(opts);
          district.setLevel("黑水县"); //行政区级别
          district.setExtensions("all");
          district.search("513228", function (status, result) {
            if (status == "complete") {
              that.getData(result.districtList[0], "map");
            }
          });
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getData(data, type) {
      let map = this[type];
      var bounds = data.boundaries;
      let polygons = [];
      if (bounds) {
        for (var i = 0, l = bounds.length; i < l; i++) {
          var polygon = new this.AMap.Polygon({
            map,
            strokeWeight: 1,
            strokeColor: "#0091ea",
            fillColor: "#80d8ff",
            fillOpacity: 0.2,
            path: bounds[i],
          });
          polygons.push(polygon);
        }
        this[type].setFitView(); //地图自适应
      }
    },
    // 控制地图全屏的开启关闭
    setFull(bol) {
      this.isFull = bol;
      !this.fullmap && this.initFullMap();
    },
    initFullMap() {
      let that = this;
      AMapLoader.load({
        key: this.$mapKey,
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: ["AMap.Scale", "AMap.ToolBar", "AMap.DistrictSearch"], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      })
        .then((AMap) => {
          that.fullmap = new AMap.Map("full", {
            //设置地图容器id
            zoom: 14, //初始化地图级别
            center: [102.987592, 32.069268], //初始化地图中心点位置
            layers: [AMap.createDefaultLayer(), new AMap.TileLayer.RoadNet()],
          });
          // 地图控件
          that.fullmap.addControl(new AMap.Scale());
          that.fullmap.addControl(new AMap.ToolBar());
          // 行政区标记
          var opts = {
            subdistrict: 1, //返回下一级行政区
            level: "city", //查询的范围
            showbiz: false, //最后一级返回街道信息
          };
          let district = new AMap.DistrictSearch(opts);
          district.setLevel("黑水县"); //行政区级别
          district.setExtensions("all");
          district.search("513228", function (status, result) {
            if (status == "complete") {
              that.getData(result.districtList[0], "fullmap");
            }
          });
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  beforeDestroy() {
    this.map && this.map.destroy();
  },
};
</script>

<style lang='scss' scoped>
.dialog {
  width: 100vw;
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2000;

  .full {
    width: 30px;
    position: absolute;
    left: 10px;
    top: 10px;
    z-index: 10;
    cursor: pointer;
  }
  #full {
    width: 100%;
    height: 100%;
  }
}

.content {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;

  .left {
    flex-grow: 1;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba($color: #aaa, $alpha: 0.3);
  }

  .full {
    width: 30px;
    position: absolute;
    left: 10px;
    top: 10px;
    z-index: 10;
    cursor: pointer;
  }

  #container {
    width: 100%;
    height: 100%;
  }

  .right {
    width: 550px;
    height: 100%;
    margin-left: 10px;
  }
  .hover-box {
    width: 100%;
    height: 100%;
    font-size: 13px;

    .video {
      width: 100%;
      max-height: 300px;
      background-color: #fff;
      padding: 20px;
      box-sizing: border-box;
      margin-bottom: 10px;
    }
    .desc-bottom {
      height: calc(100% - 355px);
      background-color: #fff;
      overflow: auto;
      padding: 20px;
    }
  }
}
@media (max-width: 1700px) {
  .content .right {
    width: 450px;
  }
}
@media (max-width: 1500px) {
  .content .right {
    width: 360px;

    .hover-box .video {
      max-height: 250px;
    }
    .hover-box .desc-bottom {
      height: calc(100% - 305px);
    }
  }
}
</style>
